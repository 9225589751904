<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="유해인자 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <q-btn
                  v-if="!popupParam.hazardCd"
                  icon="help"
                  color="deep-purple-6"
                  text-color="white"
                  class="custom-btn"
                  align="center"
                  round>
                  <q-tooltip anchor="bottom left" self="top left">
                    <div class="tooltipCustom">
                    물리적인자 선택 시, Cas No.는 입력할 수 없습니다.
                    <br>
                    화학적인자 선택 시, Cas No.의 돋보기 클릭 시 환경부-화학물질정보에서 선택할 수 있습니다.
                    </div>
                  </q-tooltip>
                </q-btn>
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveData"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-select
                  required
                  codeGroupCd="HAZARD_CLASS_FST_CD"
                  type="edit"
                  :disabled="updateMode"
                  itemText="codeName"
                  itemValue="code"
                  name="hazardClassFstCd"
                  label="유해인자 대분류"
                  v-model="data.hazardClassFstCd"
                  @datachange="datachange"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-select
                  required
                  :comboItems="occSecItems"
                  :disabled="updateMode"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="hazardClassSecCd"
                  label="유해인자 중분류"
                  v-model="data.hazardClassSecCd"
                ></c-select>
              </div>
              <!-- { name: 'close', click: true, callbackName: 'removeHazard', color: 'red' } -->
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  label="CAS NO."
                  :disabled="true"
                  name="casNo"
                  :placeholder="data.hazardClassFstCd === 'HCF0000005' || data.hazardClassFstCd === 'HCF0000010' ? '' : '입력할 수 없음'"
                  v-model="data.casNo"
                  :close="false"
                  :afterIcon="data.hazardClassFstCd === 'HCF0000005' || data.hazardClassFstCd === 'HCF0000010'? [
                    { name: 'search', click: true, callbackName: 'searchHazard', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeHazard', color: 'red' }
                    ] : null"
                  @searchHazard="addApiRow"
                  @removeHazard="removeHazard">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  :placeholder="data.hazardClassFstCd === 'HCF0000005' ||  data.hazardClassFstCd === 'HCF0000010' ? '' : '입력할 수 없음'"
                  label="화학물질명"
                  name="chemName"
                  v-model="data.chemName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  :editable="editable"
                  label="유해인자명"
                  name="hazardName"
                  v-model="data.hazardName">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-select
                  codeGroupCd="WORK_MEAS_CYCLE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="workMeasCycleCd"
                  label="작업환경측정 주기"
                  v-model="data.workMeasCycleCd"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-process
                  :editable="editable"
                  label="공정"
                  name="processCd"
                  v-model="data.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-radio
                  :editable="editable"
                  :comboItems="workFlagItems"
                  label="작업환경관련여부"
                  name="workEnvFlag"
                  v-model="data.workEnvFlag">
                </c-radio>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
                <c-radio
                  :editable="editable"
                  :comboItems="useFlagItems"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="data.useFlag">
                </c-radio>
              </div>
            </template>
          </c-card>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-table
            ref="deptTable"
            title="유해인자 취급부서 목록"
            tableId="deptTable"
            :columns="grid.columns"
            :data="data.deptList"
            :columnSetting="false"
            selection="multiple"
            :isExcelDown="false"
            rowKey="deptCd"
            :filtering="false"
            :usePaging="false"
            :hideBottom="true"
            gridHeight="305px"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <c-btn label="LBLADD" v-if="editable" icon="add" @btnClicked="addrow" />
                <c-btn label="LBLEXCEPT" v-if="editable && data.deptList.length > 0" icon="remove" @btnClicked="removeRow" />
              </q-btn-group>
            </template>
          </c-table>
        </div> -->
      </div>
      <c-dialog :param="popupOptions"></c-dialog>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'harmful-factors-detail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        hazardCd: '',
      }),
    },
  },
  data() {
    return {
      mappingType: 'POST',
      saveUrl: transactionConfig.sai.hazard.insert.url,
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revlistUrl: '',
      chemName: '',
      checkUrl: '',
      subListUrl: '',
      subSaveUrl: '',
      subDeleteUrl: '',
      editable: true,
      isSave: false,
      isdelete: false,
      isRevision: false,
      updateMode: false,
      data: {
        hazardCd: '',
        hazardClassFstCd: null,
        hazardClassSecCd: null,
        hazardName: '',
        mdmChemMaterialId: '',
        useFlag: 'Y',
        hazardId: '',
        casNo: '',
        workEnvFlag: 'Y',
        speHealthFlag: 'Y',
        workMeasCycleCd: null,
        factorMeasCycleCd: null,
        regUserId: '',
        chgUserId: '',
        processCd: '',

        deptList: [],
        deleteDeptList: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        top: '10px',
        param: {},
        closeCallback: null,
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      workFlagItems: [
        { code: 'Y', codeName: '관련' },
        { code: 'N', codeName: '미관련' },
      ],
      occSecItems: [],
      grid: {
        columns: [
          {
            name: 'deptCd',
            field: 'deptCd',
            label: '부서코드',
            align: 'center',
            style: 'width:200px',
            sortable: true,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: true,
          },
        ],
        data: [],
      },
      gridsubs: {
        columns: [
          {
            name: 'chemNmKr',
            field: 'chemNmKr',
            label: '화학물질명',
            align: 'left',
          },
        ],
        data: [],
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sai.hazard.get.url;
      this.detailDeptUrl = selectConfig.mdm.mam.chem.dept.url;
      this.insertUrl = transactionConfig.sai.hazard.insert.url;
      this.updateUrl = transactionConfig.sai.hazard.update.url;
      this.checkUrl = selectConfig.sai.hazard.check.url;
      this.deptDeleteUrl = transactionConfig.mdm.mam.chem.dept.delete.url;
      this.subListUrl = selectConfig.sai.hazard.substance.list.url;
      this.subSaveUrl = transactionConfig.sai.hazard.substance.save.url;
      this.subDeleteUrl = transactionConfig.sai.hazard.substance.delete.url;
      this.getDetail();
      // this.getSubstanse();
    },
    getDetail() {
      if (this.popupParam.hazardCd) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.hazardCd);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = this.$_.clone(_result.data);
          this.setSecondItems(_result.data.hazardClassFstCd);
          this.updateMode = true;
        },);
      }
    },
    getSubstanse() {
      if (this.popupParam.hazardCd) {
        this.$http.url = this.subListUrl;
        this.$http.type = 'GET';
          this.$http.param = {
            hazardCd: this.popupParam.hazardCd,
          };
        this.$http.request((_result) => {
          this.gridsubs.data = _result.data;
        },);
      }
    },
    saveData() {
      if (this.popupParam.hazardCd) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
      }
      let saveMsg = 'MSGSAVE';
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.checkUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            hazardId: this.data.hazardId,
            hazardName: this.data.hazardName
          };
          this.$http.request((_result) => {
            if (_result.data > 0 && !this.updateMode) {
                window.getApp.$emit('ALERT', {
                  title: 'LBLGUIDE', // 안내
                  message: '같은 유해인자가 존재합니다.',
                  type: 'warning', // success / info / warning / error
                });
                return;
            } else {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: saveMsg,
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.data.regUserId = this.$store.getters.user.userId;
                  this.data.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.hazardCd = result.data
      this.getDetail();
    },
    openHazard() {
      this.popupOptions.title = '화학자재 검색'; // 화학자재 검색
      this.popupOptions.param = {
        type: 'single',
      };
      this.popupOptions.target = () => import(`${'@/pages/common/material/chemMaterialPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHazardPopup;
    },
    closeHazardPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.data.hazardName = data[0].materialName;
        this.data.casNo = data[0].casNo;
        this.data.materialCd = data[0].materialCd;
        this.data.mdmChemMaterialId = data[0].mdmChemMaterialId;

        this.$http.url = this.detailDeptUrl
        this.$http.type = 'GET';
        this.$http.param = {mdmChemMaterialId: data[0].mdmChemMaterialId};
        this.$http.request((_result) => {
          this.data.deptList = [];
          this.$_.forEach(_result.data, item => {
            this.data.deptList.push({
                mdmChemMaterialId: this.data.mdmChemMaterialId,
                hazardCd: this.popupParam.hazardCd,
                plantCd: item.plantCd,
                plantName: item.plantName,
                deptCd: item.deptCd,
                deptName: item.deptName,
              })
          });
        },);
      }
    },
    removeHazard() {
      this.data.mdmChemMaterialId = '';
      this.data.hazardCd = '';
      this.data.materialCd = '';
      this.data.casNo = '';
      this.data.hazardName = '';
      this.data.checmName = '';
      
      this.data.hazardClassSecCd = null;
      this.setSecondItems(this.data.hazardClassFstCd)
    },
    setSecondItems(hazardClassFstCd) {
      this.$http.url = this.$format(selectConfig.sys.code.mst.list.attr.url, 'HAZARD_CLASS_SEC_CD', hazardClassFstCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.occSecItems = [];
        this.$_.forEach(_result.data, _item => {
          this.occSecItems.splice(0, 0, {
            code: _item.code,
            codeName: _item.codeName,
          })
        })
      },);
    },
    datachange() {
      this.removeHazard();
    },
    addrow() {
      this.popupOptions.title = '취급부서 추가';
      this.popupOptions.param = {
        plantCd: this.data.plantCd
      }
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.width = '40%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.data.deptList, { deptCd: item.deptCd }) === -1) {
            this.data.deptList.push({
              mdmChemMaterialId: this.data.mdmChemMaterialId,
              hazardCd: this.popupParam.hazardCd,
              plantCd: item.plantCd,
              plantName: item.plantName,
              deptCd: item.deptCd,
              deptName: item.deptName,
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeRow() {
      let selectData = this.$refs['deptTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        if (!this.data.deleteDeptList) this.data.deleteDeptList = [];
        this.$_.forEach(selectData, item => {
          if (item.editFlag !== 'C'
            && this.$_.findIndex(this.data.deleteDeptList, { deptCd: item.deptCd }) === -1) {
            this.data.deleteDeptList.push(item)
          }
          this.data.deptList = this.$_.reject(this.data.deptList, item);
        })
        this.$refs['deptTable'].$refs['compo-table'].clearSelection();
      }
    },
    addApiRow() {
      this.popupOptions.target = () => import(`${'@/pages/common/openapi/materialApiPop.vue'}`);
      this.popupOptions.title = '환경부-화학물질정보 연동 검색';
      this.popupOptions.width = '50%';
      this.popupOptions.param = {
        type: 'single'
      }
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopupApi;
    },
    closePopupApi(_data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if(_data) {
        this.$_.forEach(_data, item => {
          this.data.casNo = item.casno;
          this.data.chemName = item.mttrnmkor;
          this.data.hazardId = item.mttrid;
          // this.data.hazardName = item.mttrnmkor;
        //   // 중복 제거
        //   if (this.gridsubs.data.map(function(e) {return e.mttrid;}).indexOf(item.mttrid) < 0) {
        //     saveData.push({
        //       editFlag: 'C',
        //       hazardCd: this.popupParam.hazardCd,
        //       casNo: item.casno,
        //       chemNmKr: item.mttrnmkor,
        //       mttrid: item.mttrid,  // 환경부데이터 물질ID
        //     })
        //   }
        });
        
        
        // if(saveData.length > 0) {
        //   this.$http.url = this.subSaveUrl;
        //   this.$http.type = 'POST';
        //   this.$http.param = saveData;
        //   this.$http.request(() => {
        //     this.getSubstanse();
        //   });
        // }
      }
    },
    removeRowSubstance() {
      let selectData = this.$refs['subsTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.subDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              this.getSubstanse();
              window.getApp.$emit('ALERT', {
                title: '안내 ', /* 안내 */
                message: '삭제되었습니다.', /* 삭제되었습니다. */
                type: 'success', // success / info / warning / error
              });
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
